#slider {
  width: 500px;
  margin-bottom: 50px;

  @include breakpoint(medium) {
    width: 90%;
  }

  &:before {
    content: "GRUND";
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    left: -$box-gutter;
    top: 50%;
    transform: translate3d(-100%, -50%, 0);
  }

  &:after {
    content: "YTTERPANEL";
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    right: -($box-gutter + 10px);
    top: 50%;
    transform: translate3d(100%, -50%, 0);
  }

  @include breakpoint(medium) {
    &:before,
    &:after {
      display: none;
    }
  }
}

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
	touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
	user-select: none;
-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1; /* Fix 401 */
}
.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}
.noUi-handle {
	position: relative;
	z-index: 1;
}
.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
   its values is > 50%. */
	z-index: 10;
}
.noUi-state-tap .noUi-origin {
-webkit-transition: left 0.3s, top 0.3s;
	transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
	height: 10px;
}
.noUi-horizontal .noUi-handle {
	width: 34px;
	height: 28px;
	left: -17px;
	top: -6px;
}
.noUi-vertical {
	width: 10px;
}
.noUi-vertical .noUi-handle {
	width: 28px;
	height: 34px;
	left: -6px;
	top: -17px;
}

/* Styling;
 */
.noUi-background {
	background: rgba(#777, 0.75);
}

.noUi-origin {
	border-radius: 2px;
}
.noUi-target {
	border-radius: 30px;
  box-shadow: inset 0 1px 2px rgba(#000, 0.35), inset 0 -1px rgba(#fff, 0.75), inset 0 1px 10px rgba(#000, 0.5);
}
.noUi-target.noUi-connect {
	box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
}

/* Handles and cursors;
 */
.noUi-draggable {
	cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
	cursor: n-resize;
}
.noUi-handle {
  height: 22px !important;
  width: 40px !important;
	border-radius: 4px;
	background: #fff;
	cursor: default;
  box-shadow: 0 0 0 1px rgba(#2f2f2f, 0.5);
  margin-top: -1px;
  margin-left: 0px;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    font-family: 'ionicons';
    color: #2f2f2f;
    font-weight: bold;
    transform: scale3d(0.75,0.75,0.75);
  }

  &:before {
    left: 4px;
    content: "\f124"
  }

  &:after {
    right: 4px;
    content: "\f125";
  }
}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled].noUi-origin,
[disabled] .noUi-handle {
	cursor: not-allowed;
}


/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-pips {
	position: absolute;
	color: #999;
}

/* Values;
 *
 */
.noUi-value {
  display: none;
	width: 40px;
	position: absolute;
	text-align: center;
}
.noUi-value-sub {
	color: #ccc;
	font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
	position: absolute;
	background: #CCC;
}
.noUi-marker-sub {
	background: #AAA;
}

.noUi-marker-large {
  background: #2f2f2f;
  border-radius: 100%;
  width: 10px !important;
  height: 10px !important;
  cursor: pointer;
  transition: transform 200ms cubic-bezier(0.33, 1.15, 0.33, 1.15);
  transform: scale3d(1, 1, 1);

  @include breakpoint(small) {
    width: 8px !important;
    height: 8px !important;
  }

  &:hover {
    transform: scale3d(1.35, 1.35, 1.35);
  }

  &.current {
    border: 1px solid darken($brand, 10%);
    background: $brand;

    &:hover {
      transform: scale3d(1.25, 1.25, 1.25);
    }
  }
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
	padding: 18px 0;
	height: 50px;
	top: 100%;
	left: 0;
	width: 100%;
}
.noUi-value-horizontal {
	margin-left: -20px;
	padding-top: 20px;
}
.noUi-value-horizontal.noUi-value-sub {
	padding-top: 15px;
}

.noUi-marker-horizontal.noUi-marker {
	margin-left: -1px;
	width: 2px;
	height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
	height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
	height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
	padding: 0 10px;
	height: 100%;
	top: 0;
	left: 100%;
}
.noUi-value-vertical {
	width: 15px;
	margin-left: 20px;
	margin-top: -5px;
}

.noUi-marker-vertical.noUi-marker {
	width: 5px;
	height: 2px;
	margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
	width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
	width: 15px;
}
