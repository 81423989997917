// Main page layout

html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
}

body {
  @include flexbox(column);
  background-size: cover;
  font: 16px/1.4 $font;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  animation: fade-in 100ms 200ms ease-in-out backwards;
  height: 100vh;
  background: url('/src/images/betong-1920.jpg') no-repeat center center fixed;

  // @include breakpoint('2732') {
  //   background: url('/src/images/betong-2732.jpg') no-repeat center center fixed;
  // }
  // @include breakpoint('2048') {
  //   background: url('/src/images/betong-2048.jpg') no-repeat center center fixed;
  // }
  // @include breakpoint('1920') {
  //   background: url('/src/images/betong-1920.jpg') no-repeat center center fixed;
  // }
  // @include breakpoint('1334') {
  //   background: url('/src/images/betong-1334.jpg') no-repeat center center fixed;
  // }
  @include breakpoint('1024') {
    // background: url('/src/images/betong-1920.jpg') no-repeat center center fixed;
    height: 90vh;
  }
  @include breakpoint('small') {
    height: auto;
  }
}

.container {
  @include container($box-container);
}

// Grid
.columns {
  @include flexbox(row);

  &.wrap {
    flex-wrap: wrap;
  }
}

.column {
  flex-grow: 1;
  &.one-full { @include column(1 / 1); }
  &.one-half { @include column(1 / 2); }
  &.one-third { @include column(1 / 3); }
  &.one-fourth { @include column(1 / 4); }
  &.one-fifth { @include column(1 / 5); }
  &.one-sixth { @include column(1 / 6); }
  &.two-thirds { @include column(2 / 3); }
  &.two-fifths { @include column(2 / 5); }
  &.three-fifths { @include column(3 / 5); }
  &.three-fourths { @include column(3 / 4); }
  &.four-fifths { @include column(4 / 5); }
}
