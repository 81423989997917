.product-modal {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1280px;
  height: $box-modal;
  min-height: $box-modal;
  z-index: 99999;
  background: rgba($brand, 0.9);
  opacity: 0;
  transition: transform 250ms cubic-bezier(0.5, 0.25, 0.33, 1.15), opacity 100ms ease;
  color: #fff;
  border-radius: 4px;
  margin-top: $box-header;
  -webkit-transform: translateZ(0);

  @include breakpoint('1280') {
    width: 90vw;
    max-height: 90vh;
  }
  @include breakpoint('large') {
    @media (orientation : portrait) {
      margin-top: $box-header / 2;
      height: 85vh;
    }
  }

  @include breakpoint('small') {
    width: 100%;
    height: auto !important;
    border-radius: 0;
    margin-top: 0;
    top: $box-header;
    transform: translate3d(-50%, 0, 0) scale3d(1, 1, 1) !important;

    .columns {
      @include flexbox(column);

      .column {
        width: 100%;
      }
    }
  }

  .container {
    @include flexbox(column);
    // min-height: $box-modal;
    // justify-content: center;
    padding-left: $box-gutter * 2;
    padding-right: $box-gutter * 2;
    max-width: 100%;
    overflow-y: auto;
    max-height: inherit;
    height: inherit;
  }

  &.disabled {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
    pointer-events: none;
  }

  &.enabled {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
    pointer-events: all;
  }
}

h1.title {
  font-weight: 600;
  margin-top: $box-gutter * 1.5;
  margin-bottom: 10px;
}

.description {
  padding-bottom: $box-gutter;
  border-bottom: 2px solid #000;
}

h3 {
  font-weight: 600;
}


.product {
  padding-top: $box-gutter;
  padding-bottom: $box-gutter;

  &:not(:last-of-type) {
    border-bottom: 2px solid #000;
  }
}

.links {
  @include flexbox(column);
  justify-content: flex-start;
  margin-top: 25px;
  margin-left: $box-gutter;
  text-align: center;

  @include breakpoint('small') {
    margin-top: $box-gutter;
    margin-left: 0;
  }
}

.video {
  @include flexbox(column);
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: inherit;
  background: rgba(#000, 1);
  transition: opacity 200ms ease-in-out;
  opacity: 0;

  &.disabled {
    pointer-events: none;
    opacity: 0;
  }

  &.enabled {
    pointer-events: all;
    opacity: 1;
  }

  iframe {
    z-index: 9998;
  }
}

.video-close {
  display: none;
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 26px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  z-index: 9999;

  &:hover {
    transform: scale3d(1.1, 1.1, 1.1);
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 26px;
  color: #fff;
  cursor: pointer;
  user-select: none;

  &:hover {
    transform: scale3d(1.1, 1.1, 1.1);
  }
}
