// Media breakpoints
@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and (max-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
}

// Gradient background for buttons
@mixin btn-gradient($color: $default) {
  background: linear-gradient($color, darken($color, 10%));

  &:hover {
    background: linear-gradient(darken($color, 5%), darken($color, 15%));
  }
}

// Centered container with variable width
@mixin container($width) {
  width: 100%;
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $box-gutter;
  padding-right: $box-gutter;
}

// Grid layout
@mixin column($frac: 1) {
  width: percentage($frac);
  flex-grow: 0;
}

// Input placeholder
@mixin placeholder {
   ::-webkit-input-placeholder { @content; }
   :-moz-placeholder { @content; }
   ::-moz-placeholder { @content; }
   :-ms-input-placeholder { @content; }
}

// Flexbox with variable direction
@mixin flexbox($dir) {
  display: flex;
  flex-direction: $dir;
}

// Increasing transition delay
@mixin transition-delay($init, $x, $amount, $type: 'transition-delay') {
  @for $i from 1 through $amount {
    &:nth-of-type(#{$i}) {
      transition-delay: $init + ($i * $x);
    }
  }
}
