.btn-round {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background: $brand;
  border-radius: 50%;
  border: 1px solid darken($brand, 15%);
  cursor: pointer;
  z-index: 9998;
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms 250ms ease;
  transform: translate3d(-50%, -50%, 0);

  @include breakpoint(small) {
    width: 16px;
    height: 16px;
  }

  &.disabled {
    opacity: 0;
    pointer-events: none;
  }

  &.enabled {
    opacity: 1;
    pointer-events: all;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: -8px;
      margin-top: -8px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-shadow: 0 0 0 2px rgba($brand, 1);
      content: "";
      opacity: 0;
      animation: pulse 2000ms 100ms ease-in-out 1;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: -2px;
      margin-top: -2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgba($brand, 0.8);
      content: "";
      animation: pulse 1750ms ease-in-out infinite;
    }
  }
}

.btn {
  background: #000;
  border-radius: 4px;
  padding: 4px;
  margin: 2px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background 200ms ease;

  &:hover {
    background: rgba(#000, 0.75);
    text-decoration: none;
  }

  span[class*=ion] {
    font-size: 26px;
    margin-left: 10px;
    vertical-align: middle;
  }
}
