// Global typography styles

h1,
h2,
h3,
h4,
p {
  font-weight: 400;
}


h1 { font-size: 32px; }
h2 { font-size: 24px; }
h3 { font-size: 18px; }
h4 { font-size: 17px; }

a {
  text-decoration: none;
  color: $default;

  &[href]:not([class]) {
    color: $default;
  }

  &:visited {
    color: $default;
  }

  &:hover {
    text-decoration: underline;
  }
}

small {
  font-size: $text-small;
}

strong {
  font-weight: 600;
}
