// Main page section

.main {
  @include flexbox(column);
  justify-content: center;
  flex: 1;

  @include breakpoint('small') {
    display: block;
  }

  .container {
    @include flexbox(column);
    margin-top: $box-header;
    padding-top: $box-gutter;
    padding-bottom: $box-gutter;
    align-items: center;


    @media screen and (max-height: 725px) {
      max-width: 896px;
    }

    @media screen and (max-height: 650px) {
      max-width: 768px;
    }

    @media screen and (max-height: 575px) {
      max-width: 512px;
    }
  }
}

.canvas-wrap {
  @include flexbox(column);
  position: relative;
  overflow: hidden;
  transition: transform 250ms cubic-bezier(0.33, 1.25, 0.33, 1.25), opacity 200ms ease-in-out;
  z-index: 998;
  animation: 'scale-in' 250ms 750ms cubic-bezier(0.33, 1.1, 0.33, 1.1) backwards;
  background: $brand;
  border: 10px solid $brand;
  border-radius: 4px;

  &.minimize {
    transform: scale3d(0.85, 0.85, 1);
  }

  &:hover {
    .next-slide,
    .previous-slide {
      opacity: 1;
      transform: translate3d(0, -50%, 0);
    }
  }
}

canvas {
  z-index: 999;
  user-select: none;
  max-width: 100% !important;
  height: auto !important;
  border-radius: 2px;
}

.next-slide,
.previous-slide {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background: rgba($brand, 0.65);
  position: absolute;
  z-index: 9999;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  transition: background 100ms ease, opacity 100ms ease-in-out, transform ease-in-out;

  &:after {
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: #fff;
    font-size: 26px;
    font-family: 'Ionicons';
  }

  @include breakpoint(small) {
    width: 32px;
    height: 32px;

    &:after {
      font-size: 18px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

body:not([class='mobile']) {
  .next-slide,
  .previous-slide {
    opacity: 0;
    transition: background 100ms ease, opacity 100ms ease-in-out, transform 100ms ease-in-out;
  }

  .next-slide {
    transform: translate3d(100%, -50%, 0);
  }

  .previous-slide {
    transform: translate3d(-100%, -50%, 0);
  }
}

.next-slide {
  right: 12px;

  @include breakpoint(small) {
    right: 2px;
  }

  &:after {
    content: "\f125";
    left: 52.5%;
  }
}

.previous-slide {
  left: 12px;

  @include breakpoint(small) {
    left: 2px;
  }

  &:after {
    content: "\f124";
    left: 47.5%;
  }
}
