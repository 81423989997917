@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slide-right {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translate3d(0, -60px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes scale-in {
  from {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    opacity: 0;
    transform: scale(1, 1, 1);
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
    transform: scale3d(1.75, 1.75, 1.75);
  }
  to {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }
}
