.tooltip {
  // The tooltip
  &:after {
    position: absolute;
    top: -100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0) scale3d(0.75, 0.75, 0.75);
    transition: transform 100ms ease, opacity 100ms ease;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: break-word;
    white-space: pre;
    font-size: $text-small;
    font-weight: 600;
    color: #fff;
    background: rgba(#000, 0.9);
    border-radius: 3px;
    content: attr(aria-label);
    opacity: 0;
    pointer-events: none;
    z-index: 9999;
  }

  // The arrow
  &:before {
    opacity: 0;
    position: absolute;
    border: 5px solid transparent;
    border-top-color: rgba(#000, 0.9);
    content: "";
    pointer-events: none;
    right: 50%;
    transition: transform 100ms ease, opacity 100ms ease;
    transform: translate3d(50%, -12px, 0) scale3d(1, 1, 1);
    top: -50%;
    z-index: 9998;
  }

  // Trigger
  &:hover,
  &:active,
  &:focus {
    &:after,
    &:before {
      opacity: 1;
    }
  }
}

// Btn trigger
.btn-round {
  &:hover {
    .tooltip {
      &:after {
        transform: translate3d(-50%, -16px, 0) scale3d(1, 1, 1);
        opacity: 1;
      }
      &:before {
        opacity: 1;
        transform: translate3d(50%, 0, 0) scale3d(1, 1, 1);
      }
    }
  }
}
