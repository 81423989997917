// #canvas[data-value="17"] {
//   .btn-round[id="210"] {
//     display: block;
//     top: 49%;
//     left: 18%;
//   }
//
//   .btn-round[id="215"] {
//     display: block;
//     top: 74%;
//     left: 51%;
//   }
//
//   .btn-round[id="212"] {
//     display: block;
//     top: 17%;
//     left: 45%;
//   }
// }

.preload {
  display: none;
}

.api-error {
  background: #eee;
}
