// Box model
$box-container: 1024px;
$box-gutter: 22px;
$box-header: 60px;
$box-modal: 512px;

// Colors
$default: #fff;
$primary: #add8e6;
$success: #008000;
$warning: #ff0;
$danger: #f00;
$brand: #40a630;
$background: #bbb;
$foreground: #fff;
$accent: #fff;

// Text
$font: Helvetica, sans-serif;
$text-small: 12px;
$text-large: 16px;
$text-xlarge: 20px;

// Breakpoints
$breakpoints: (
  'small': 767px,
  'medium': 896px,
  'large': 1024px,
  '1280': 1324px,
  '1024': 1068px,
);
