// Header specific styles

.masthead {
  @include flexbox(row);
  background: $brand;
  height: $box-header;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  animation: 'slide-down' 200ms 250ms ease backwards;

  @include breakpoint('small') {
    position: absolute;
  }

  .page-title {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: $box-gutter;

    @include breakpoint(small) {
      font-size: 12px;
    }
  }

  .container {
    @include flexbox(row);
    align-items: center;
    position: relative;

    > *:not(:last-of-type) {
      margin-right: $box-gutter;
    }
  }

  .logo {
    img {
      margin-top: 5px;
      height: 20px;
    }
  }

  .main-menu-icon {
    color: #fff;
    cursor: pointer;
    vertical-align: middle;
    animation: 'slide-right' 200ms 400ms ease backwards;
    background: rgba(#000, 1);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    user-select: none;

    &:hover {
      background: rgba(#000, 0.75);
    }

    &:after {
      content: "Meny";
      font-size: 16px;
      font-weight: 600;
      vertical-align: middle;
      margin-left: 4px;
    }

    &:before {
      vertical-align: middle;
      font-size: 24px;
      -webkit-font-smoothing: antialiased;
      font-family: 'Ionicons';
      content: '\f20d';
    }

    &.menu-open {
      &:before {
        content: "\f129";
      }
    }
  }
}

.main-menu {
  @include flexbox(column);
  box-sizing: border-box;
  flex: 1;
  position: absolute;
  top: $box-header - 1px;
  left: 0;
  width: 100%;
  max-height: 100vh;
  padding: $box-gutter 0;
  z-index: 9999;
  overflow-y: auto;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  opacity: 0;
  pointer-events: none;

  .main-menu-title {
    font-weight: 600;
    padding-left: $box-gutter;
    padding-right: $box-gutter;
    padding-bottom: $box-gutter;
    text-transform: uppercase;
  }

  .column {
    padding-left: $box-gutter;
    padding-right: $box-gutter;
  }

  .column {
    &:not(:last-of-type) {
      border-right: 2px solid #fff;
    }
  }

  &.enabled {
    pointer-events: all;
    opacity: 1;
    background: rgba($brand, 0.95);
    border: 1px solid darken($brand, 5%);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0;
  }

  .menu-item {
    display: block;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    padding: 4px $box-gutter;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      text-decoration: none;
      background: darken($brand, 5%);
    }
  }
}
